















































































import { BCard, BCol, BContainer, BOverlay, BRow } from "bootstrap-vue";
import { addMonths, parse, format, parseISO } from "date-fns";
import { Component, Vue, Watch } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import "flatpickr/dist/flatpickr.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { default as CustomSelect, default as Select } from "@/layouts/components/Select.vue";

import { $themeColors } from "../../../themeConfig";
import userModel from "@/api/user.model";
import chatModel from "@/api/chat.model";
import corporateClientModel from "@/api/corporate_client.model";
import { showErrorAlert } from "@/helpers";
import MetricsCard from "./MetricsCard.vue";
import SimpleMetricsCard from "./SimpleMetricsCard.vue";
import { IReport } from "@/interfaces/report";

@Component({
	components: {
		flatPickr,
		BContainer,
		BCard,
		BRow,
		BCol,
		BOverlay,
		vSelect,
		MetricsCard,
		SimpleMetricsCard,
		Select,
		CustomSelect,
	},
})
export default class Dashboard extends Vue {
	isLoading = false;
	corporateClientIdFilter = null;
	isCorporateClientLoading = false;
	corporateClients: { label: string; code: string }[] = [];

	groupByOptions = [
		{ label: "Dia", code: "DAY" },
		{ label: "Semana", code: "WEEK" },
		{ label: "Mês", code: "MONTH" },
	];

	metricsOptions = {
		groupBy: "DAY",
		countType: "CUMULATIVE",
		startDate: addMonths(new Date(), -1),
		endDate: new Date(),
	};

	flatPickrConfig = {
		locale: Portuguese,
		enableTime: false,
		dateFormat: "Z",
		altInput: true,
		altFormat: "d/m/Y",
	};

	charts: any[] = [{ model: userModel, title: "Usuários", definition: { options: {} } }];
	userAdminModel = userModel;
	chatModel = chatModel;
	reports: IReport = {};
	reportsKeys: (keyof IReport)[] = [];
	reportsDictionary = {
		receivedMessages: "Mensagens recebidas",
		sendedMessages: "Mensagens enviadas",
		totalHSM: "Templates enviados",
		totalChannelClosed: "Conversas encerradas",
		totalChannelOpened: "Em atendimento",
		totalUserActive: "Operadores disponíveis",
		totalUserAway: "Operadores em pausa",
		totalReceptiveQueue: "Em fila",
	};

	async mounted() {
		await this.changedMetricsOptions(this.metricsOptions);
		await this.loadCorporateClients();
		await this.loadCardMetrics();
	}

	refreshDashboard() {
		const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
		this.reportsKeys = keys(this.reports);
	}

	async loadCorporateClients() {
		const corporateClients = await corporateClientModel.get("");
		this.corporateClients = corporateClients.map((corporateClient: any) => {
			return { label: corporateClient.name, code: corporateClient.id };
		});
	}

	async loadCardMetrics(corporateClientId?: string) {
		try {
			const userResponse = await this.userAdminModel.report(corporateClientId);
			const chatResponse = await this.chatModel.report(corporateClientId);
			Object.assign(this.reports, userResponse, chatResponse);
			this.refreshDashboard();
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	@Watch("metricsOptions", { deep: true })
	async changedMetricsOptions(options: any) {
		this.isLoading = true;
		try {
			for (const chart of this.charts) {
				const type = "bar"; // options.countType === "CUMULATIVE" ? "line" : "bar";
				chart.definition = await this.getChart(
					await chart.model.getMetrics({ ...options, countType: undefined }, chart.subEntity),
					type,
					chart.title,
				);
			}
		} catch (error) {
			showErrorAlert("Não foi possível carregar. Tente novamente.");
			console.error(error);
		}
		this.isLoading = false;
	}

	@Watch("corporateClientIdFilter")
	async changeChatMetrics() {
		if (![null, undefined].includes(this.corporateClientIdFilter)) {
			await this.loadCardMetrics(this.corporateClientIdFilter!);
		} else {
			await this.loadCardMetrics();
		}
	}

	getChart(metrics: any, type: string, title: string) {
		return {
			title,
			type,
			series: [
				{
					name: title,
					data: metrics.map((dataPoint: any) =>
						this.metricsOptions.countType === "CUMULATIVE" ? dataPoint.cumulativeCount : dataPoint.count,
					),
				},
			],
			options: {
				theme: {
					monochrome: {
						enabled: true,
						color: (<any>$themeColors)?.primary ?? "#000000",
						shadeTo: "light",
						shadeIntensity: 0.65,
					},
				},
				dataLabels: { enabled: false },
				stroke: { curve: "smooth" },
				xaxis: {
					title: {
						text: this.groupByOptions.find(option => option.code === this.metricsOptions.groupBy)?.label,
						style: { fontSize: "12px", fontWeight: "bold", color: "#555" },
					},
					categories: metrics.map((dataPoint: any) => this.formatPeriod(dataPoint.period)),
				},
			},
			firstValue: metrics[0]?.cumulativeCount,
			lastValue: metrics[metrics.length - 1]?.cumulativeCount,
		};
	}

	formatPeriod(period: string) {
		try {
			switch (this.metricsOptions.groupBy) {
				case "DAY":
					return format(parseISO(period), "dd/MM/yyyy");
				case "WEEK":
					return format(parse(period, "RRRR:II", new Date()), "dd/MM/yyyy");
				case "MONTH":
					return format(parse(period, "yyyy-MM", new Date()), "MMM yyyy");
			}
		} catch (error) {
			console.error(error);
			return "";
		}
	}
}
